body {
	font-size: 0.875rem;
}

.navbar-brand {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	text-align: center;
	background-color: white !important;
}

.navbar-brand img {
	width: 150px;
}

.feather {
	width: 16px;
	height: 16px;
	vertical-align: text-bottom;
}

/*
   * Sidebar
   */

.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	/* Behind the navbar */
	padding: 48px 0 0;
	/* Height of navbar */

	box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.navbar-dark .navbar-nav .nav-link {
	color: white;
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: #e1f5fe;
}

.bg-light-blue {
	padding-top: 65px;
	background-color: #e1f5fe;
}

.bg-dark-blue {
	background-color: #2196f3;
}

.sidebar-sticky {
	position: relative;
	top: 0;
	height: calc(100vh - 48px);
	padding-top: 0.5rem;
	overflow-x: hidden;
	overflow-y: auto;
	/* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
	.sidebar-sticky {
		position: -webkit-sticky;
		position: sticky;
	}
}
.modal-dialog{
	min-width: 80%!important;
    padding: 2rem;
}
.sidebar .nav-link {
	font-weight: 500;
	color: #333;
	/* color: #333; */
}

.sidebar .nav-link .feather {
	margin-right: 4px;
	color: #999;
}

.sidebar .nav-link.active {
	color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
	color: inherit;
}

.sidebar-heading {
	font-size: 0.75rem;
	text-transform: uppercase;
}

/*
   * Content
   */

[role="main"] {
	padding-top: 133px;
	/* Space for fixed navbar */
}

@media (min-width: 768px) {
	[role="main"] {
		padding-top: 48px;
		/* Space for fixed navbar */
	}
}

/*
   * Navbar
   */

.navbar-brand {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	font-size: 1rem;
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .form-control {
	padding: 0.75rem 1rem;
	border-width: 0;
	border-radius: 7px;
}

.form-control-dark {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.1);
	border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
	border-color: transparent;
	box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

.sidebar-sticky::-webkit-scrollbar {
	width: 10px;
}

.sidebar-sticky::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
	/* border-radius: 10px; */
}

.sidebar-sticky::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}
@media (max-width: 575px) {
	.bg-light-blue {
		padding-top: 100px;
	}
}
@media (max-width: 340px) {
	.bg-light-blue {
		padding-top: 140px;
	}
}
/* Printing */
@media print {
	.table,
	.table tr,
	.table th,
	.table td {
		border: 1px solid black;
		padding: 5px;
	}

	.sidebar {
		display: none;
		width: 0 !important;
	}

	.btn {
		display: none;
	}

	main {
		width: 100% !important;
		max-width: 100% !important;
	}
}
