.modal-content {
	width: 90%;
	margin-left: 10%;
	margin-right: 10%;
	padding: 0 3rem;
}

#order-modal .params-row {
	margin-bottom: 10px;
}

#order-modal .row > div:not(:first-child) {
	/* border:1px solid black; */
	padding-left: 2px;
}

#order-add-table ul {
	margin-bottom: 0;
}

.form-control-cat {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.form-control-chip {
	flex: 0 0 auto;
	margin: 10px;
}
.form-control-chips {
	display: grid;
	flex-wrap: wrap;
	justify-content: center;
}
#order-modal .form-control-chips .form-control-chip.active {
	box-shadow: 0px 0px 0px 5px #7dce13;
}

#order-modal .form-control-chips .cat-chip.active {
	box-shadow: 0px 0px 0px 5px #11316f;
}

#order-modal .form-control-chips .form-control-chip {
	color: black;
	font-weight: bold;
	margin: auto 10px;
}

#order-modal .form-control-cat .form-control-cat {
	color: black;
	font-weight: bold;
	margin: 5px 10px;
	width: 120px;
}

#order-modal .form-control-cat .form-control-chip.active {
	box-shadow: 0px 0px 0px 5px #7dce13;
}

#order-modal .form-control-cat .cat-chip.active {
	box-shadow: 0px 0px 0px 5px #11316f;
}

#order-modal .form-control-cat .form-control-chip {
	color: black;
	font-weight: bold;
	margin: 5px 10px;
}

#order-modal .form-control-cat .form-control-cat {
	color: black;
	font-weight: bold;
	margin: 5px 10px;
	width: 120px;
}

.form-control-chip.axelle38 {
	background: #0652dd;
}

.form-control-chip.axelle38prs {
	background: #0652dd;
}

.form-control-chip.axelle70prs {
	background: #ffb142;
}

.form-control-chip.axelle70 {
	background: #f6e58d;
}

.form-control-chip.axelletorique38 {
	background: #ff3838;
}

.form-control-chip.axelletorique70 {
	background: #ced6e0;
}

.form-control-chip.axelletorique38prs {
	background: #be2828;
}

.form-control-chip.axelletorique70prs {
	background: #acb6c3;
}

.form-control-chip.verde {
	background: #7bed9f;
}

.form-control-chip.giallo {
	background: #ffd32a;
}

.form-control-chip.perla {
	background: #d2dae2;
}

.form-control-chip.spice {
	background: #8d6e63;
}

.form-control-chip.dkkera {
	background: #64b5f6;
}

#order-modal .form-control-chips .form-control-chip.chip-blue {
	background: #3498db;
	color: white;
}

#order-modal .form-control-chips .form-control-chip.chip-blue.active {
	border: 1px solid #2980b9;
}

/* DONE BY YACINE */
.cat-chip {
	display: inline-block;
	color: #111;
	border: 0px solid #ccc;
	background: #007bff;
	box-shadow: 0 0 5px -1px rgb(0 0 0 / 20%);
	cursor: pointer;
	vertical-align: middle;
	padding: 10px;
	text-align: center;
	border-radius: 5px;
	width: 120px;
}

.form-control-chip {
	display: inline-block;
	color: #111;
	border: 0px solid #ccc;
	background: #6096ba;
	box-shadow: 0 0 5px -1px rgb(0 0 0 / 20%);
	cursor: pointer;
	vertical-align: middle;
	padding: 10px;
	text-align: center;
	border-radius: 5px;
	width: 120px;
}

.large-cat {
	width: 165px !important;
}

.sclerale-torique {
	max-width: 130px;
}

.large-label {
	max-width: 140px;
}

.hidden {
	display: none;
}

.disabled {
	box-shadow: none !important;
}

.disabled:hover {
	cursor: not-allowed;
}

.disabled:hover::before {
	content: "Produit non disponible!"; /* Set the message content */
	position: absolute; /* Position the element absolutely */
	top: -20px; /* Move the element up */
	left: 0; /* Align the element with the div */
	padding: 5px; /* Add some padding to the element */
	background-color: #333; /* Set the background color */
	color: #fff; /* Set the text color */
	font-size: 12px; /* Set the font size */
	border-radius: 5px; /* Add some rounded corners */
}

.sign-missing-input::after {
	content: "Un signe doit se placer en première position !"; /* Set the message content */
	position: absolute; /* Position the element absolutely */
	top: -20px; /* Move the element up */
	left: 0; /* Align the element with the div */
	padding: 5px; /* Add some padding to the element */
	background-color: #333; /* Set the background color */
	color: #fff; /* Set the text color */
	font-size: 12px; /* Set the font size */
	border-radius: 5px; /* Add some rounded corners */
}

.parse-error-input::before {
	content: "Erreur le champ doit contenir un nombre !"; /* Set the message content */
	position: absolute; /* Position the element absolutely */
	top: -20px; /* Move the element up */
	left: 0; /* Align the element with the div */
	padding: 5px; /* Add some padding to the element */
	background-color: #333; /* Set the background color */
	color: #fff; /* Set the text color */
	font-size: 12px; /* Set the font size */
	border-radius: 5px; /* Add some rounded corners */
}

.top {
	grid-area: 1;
}

.secondrow {
	grid-area: 2;
}

.bottom {
	grid-area: 3;
}
