.form-control-chips {
	margin: 40px 0px;
	margin-left: 0;
	display: block;
	display: grid;
	grid-row-gap: 20px;
}

.form-control-cat {
	margin: 40px 0px;
	margin-left: 0;
	display: block;
}

.form-control-chip {
	display: inline-block;
	text-align: center;
	padding: 5px 8px;
	margin: 0px 3px 5px 3px;
	background-color: #6096ba;
	color: #3c4043;
	border-radius: 56px;
	font-weight: 400;
	font-size: 11pt;
	border: 1px solid #dfe1e5;
	min-width: 100px;
}

.form-control-chip:hover {
	cursor: pointer;
	transform: scale(1.05);
}

.custom-control-input {
	z-index: 2 !important;
}

.custom-control-label {
	font-size: 12pt;
}

.custom-control-label::before,
.custom-control-label::after {
	top: 0.1rem;
	width: 1.25rem;
	height: 1.25rem;
}

.form-control.is-invalid {
	border-color: #dc3545;
	background-image: none !important;
	border-width: 1.6px;
	padding-right: 0.75rem;
}

.form-control.is-invalid-format {
	border-color: #dc3545;
	background-image: none !important;
	border-width: 1.6px;
	padding-right: 0.75rem;
}

.bold-form::placeholder {
	font-weight: normal;
}

.bold-form {
	font-weight: bold;
}
