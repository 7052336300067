#overlay-background {
	height: 100vh;
	width: 100vw;
	position: absolute;
	top: 0;
	left: 0;
	background-color: white;
	text-align: center;
	z-index: 1050;
	padding-top: 10em;
}

#overlay-background .spinner {
	width: 50px;
	height: 50px;
}

#overlay-background h1 {
	font-size: 48pt;
	font-weight: 400 !important;
}

#overlay-background p {
	font-size: 28pt;
}

#overlay-background img {
	max-width: 500px;
	margin-bottom: 30px;
	/*animation: blinker 2s linear infinite;*/
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}
