#order-filters{
    margin-bottom: 10px;
}

#order-filters .row div{
    /* margin-right: 2px; */
    padding-right: 2px;
}
#order-filters .react-datepicker-wrapper{
    display: block;
}