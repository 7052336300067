#main-container {
	width: 100%;
}

.search-form {
	display: flex;
	justify-content: center;
	width: 35%;
}

.search-title {
	color: #fff;
	margin: auto 0px;
	font-size: 1.2rem;
	margin-right: 30px;
}

.search-input {
	background-color: white;
}

.search-button {
	background: transparent;
	border: none;
	outline: none;
	margin-left: -50px;
}

.search-button img {
	width: 20px;
	height: 20px;
	object-fit: cover;
}

.checkboxes {
	display: flex;
	justify-content: end;
}

.custom-checkbox-hidden {
	display: none;
}
