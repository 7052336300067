#order-modal-stock{
    min-width: 700px;
}
#order-modal-stock .modal-body{
    height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
#order-modal-stock .modal-body #params-row{
    padding-right: 0!important;
    padding-left: 0!important;
}
#order-modal-stock #params-row .col-sm-6{
    padding-right: 0!important;
}
#order-modal-stock .powers-row{
    padding-right: 0!important;
    margin-bottom: 10px;
    margin-left:20px;
    margin-top:25px;
}
#order-modal-stock #powers-choose-group{
    margin-top:20px;
    margin-bottom:20px;
}
#order-modal-stock .row.powers-row > div{
    padding-right: 0;
    padding-left:0;
}
/* #order-modal-stock .row > div:nth-child(4n + 1){
    padding-right: 2px;
    padding-left:10px;
}
#order-modal-stock .row > div:nth-child(4n + 2){
    padding-right:3px;
}
#order-modal-stock .row > div:nth-child(4n){
    padding-left: 2px;
    padding-right: 10px;;
} */
#order-add-table ul{
    margin-bottom: 0;
}
#order-modal-stock .powers-row label{
    font-weight: bold;
}
#order-modal-stock .powers-row .col-3:first-child label{
    /* margin-left:3px;
    margin-right:3px; */
}
/* #order-modal-stock .powers-row .col-3.positive-powers:not(:first-child) label{
    margin-left:3px;
    margin-right:3px;
} */
#order-modal-stock .powers-row .form-control{
    display: inline;
    width: 50%;
    margin-right:30px;
    margin-top:-10px;
    margin-bottom: 15px;
    float: right;
}
#order-modal-stock .form-control-chips{
}
#order-modal-stock .form-control-chips .form-control-chip{
    width: 48%;
    margin: 0;
    color: white;
    text-transform: capitalize;
}
#order-modal-stock .form-control-chips .form-control-chip.active{
    box-shadow: 0px 0px 5px 5px #353b48;
}
#order-modal-stock .form-control-chips .form-control-chip.negative-chip{
    background: #007bff;
}
#order-modal-stock .form-control-chips .form-control-chip.positive-chip{
    background: #dc3545;
}
#order-modal-stock .form-control-chips .form-control-chip:first-child{
    margin-right:10px;
}