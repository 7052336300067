#orders-table, #orders-table thead, #orders-table tbody{
    width: 100px;
}
#orders-table td{

}
#group-actions .row{

}

#group-actions .row .col{
    margin-right: 0;
    padding-right: 0;
}
#group-actions .row .col:not(:first-child){
    padding-left: 1px;
}